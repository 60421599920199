import React, { useEffect, useState } from 'react';
import { getResource } from '../../services/api';

const Contact = () => {
    const [layout, setLayout] = useState(null)
    useEffect(() => {
        getResource(`layout`).then(res => {
            setLayout(res.data)
        })
    }, [])
    return (
        <div id="motdirecteur" class="motdirecteur">
            <section class="welcome-section">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="welcome-wrap">
                                <div class="welcome-text pt-lg-5">
                                    <h2 class="section-title ">Comité National du Codex Alimentarius</h2>
                                    <p>Conformément aux dispositions de l'article 10 du décret n°2020-027 du 15 janvier 2020
                                        portant attributions, organisation et fonctionnement du Ministère de l'Agriculture, de l'Elevage et de la Pêche,
                                        la Direction de l'Alimentation et de la Nutrition Appliquée est l'organe de conception et de suivi évaluation des
                                        politiques et stratégies de l'Etat en matière de prévention des déséquilibres alimentaires et de promotion
                                        d'un mode alimentaire sain et adapté.
                                    </p>
                                    <a class="btn btn-sm btn-secondary" href="/content/aof-dana">A propos</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3">
                            <div class="facts-counter">
                                <ul>
                                    <li class="bg-light-green">
                                        <a href="/content/historique-dana" class="text-white text-uppercase">
                                            <div class="fact-item d-flex">
                                                <div class="fact-icon">
                                                    <img src={'/img/budget.png'} alt="Categories" />
                                                </div>
                                                <div>Historique
                                                </div>
                                            </div>
                                        </a>

                                    </li>
                                    <li class="bg-red">
                                        <a href="/documentations/71e2efa6-f5a2-4f00-93bc-ef3cebffe4eb/textes-reglementaires" class="text-white text-uppercase">
                                            <div class="fact-item d-flex">
                                                <div class="fact-icon">
                                                    <img src={'/img/law.png'} alt="Categories" />
                                                </div>
                                                <div>Textes réglementaires <br />
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="bg-green">
                                        <a href="/nouveautes" class="text-white text-uppercase">
                                            <div class="fact-item d-flex">
                                                <div class="fact-icon">
                                                    <img src={'/img/document.png'} alt="Categories" />
                                                </div>
                                                <div>Actualités
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="welcome-image"></div>
            </section>
        </div>
    );
}
export default Contact;