import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Card } from 'reactstrap';
import { sliderConfig } from '../../constants';
import { getResource } from '../../services/api';
import moment from "moment"
import 'moment/locale/fr'
moment.locale('fr')


const SliderAccueil = ({ home }) => {

    const [publications, setPublications] = useState([])

    getResource(`documents?module.libUnique=slider-dana`).then(res => {
        setPublications(res.data['hydra:member'])
    })

    return (
        <div id="Homeslider" className="container-fluid pt-0">
            <div className="row">
                <div className="slick-slider">
                    <div className="slick-slider-init">
                        <Slider {...sliderConfig}>
                            {
                                publications && publications.map(slider => (
                                    <div className="slick-item" key={slider.id}>
                                        <div class="dgi-slide-item w-100 bg-secondary text-white"
                                            style={slider.fichier && { backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + slider.fichier.contentUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderAccueil;