import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { getResource } from '../../services/api';

const CountNumber = ({ home }) => {

    return (
        <div id="countNumber " className='bg-light'>
            <div className="p-2">
                <div className="row fw-bold text-center titleCounter">
                    <div className="col-md-3 ">
                        <img src={'/img/logo-MAEP.png'} alt="logo" width='300px' class="img-fluid" />
                    </div>
                    <div className="col-md-3 ">
                        <img src={'/img/1.png'} alt="logo" width='280px' class="img-fluid" />
                    </div>
                    <div className="col-md-3 ">
                        <img src={'/img/OMS.png'} width='300px' className='img-fluid' />
                    </div>
                    <div className="col-md-3 ">
                        <img src={'/img/FAO.png'} className='img-fluid' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CountNumber;