import React, { useState, useEffect } from 'react';
import { getResource } from '../../services/api';
import Loader from '../../lib/loader';

import moment from 'moment'
import { ITEM_PER_PAGE } from '../../constants';

function ServicesUsuels() {

    const [services, setServices] = useState([]);
    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        loadData()
    }, [search]);

    useEffect(() => {
        loadData()
    }, [currentPage]);

    const loadData = () => {
        setLoading(true)
        getResource(`catalogues?itemsPerPage=${ITEM_PER_PAGE}&page=${currentPage}${search ? `&libelle=${search}` : ''}`).then(res => {
            // console.log(res.data)
            setServices(res.data['hydra:member'])
            let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
            setLoading(false)
        })
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Services</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> -</span>
                            <span>SERVICES</span>
                        </div>
                    </div>
                </div>


            </div>

            <div className="container mb-3 mt-3">
                <div className="searchContainer dgishadow rounded">

                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>

                        <div className="icon" title="Clique pour Recherchez">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                    {/* <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>


                    </div> */}
                </div>
                {
                    loading == true ? <Loader /> : services.length == 0 ? <div className="d-flex w-100 justify-content-between">
                        <h5 className="commiqueTitle text-center">Aucune donnée disponible</h5>
                    </div> : <div className=" mt-3">
                        <div className="list-group">

                            {services.map(us => (

                                <a href={`services/${us.libelle}`} className="aservice"><li key={us.id} className="list-group-item d-flex justify-content-between align-items-start service-usuel__item">
                                    <div className="me-auto pb-4 pt-4">
                                        <div className="title">{us.libelle}</div>
                                        <small>{us.departement.libelle}</small>
                                    </div>
                                </li>
                                </a>

                            ))}
                        </div>


                    </div>
                }
                <div className="searchContainer dgishadow rounded">
                    <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <h4 style={{ color: '#fff' }}>Services</h4>
                                </li>
                            </ul>
                        </nav>


                    </div>
                    <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default ServicesUsuels;