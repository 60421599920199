import React, { useEffect, useState } from 'react'
import youtube from "../../services/youtube";
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    TelegramShareButton,
} from "react-share";
import { getResource } from '../../services/api';
import moment from "moment"
import 'moment/locale/fr'
import YoutubePlay from './youtubePlay';
moment.locale('fr')



const Actualites = ({ home }) => {
    const [nextToken, setNextToken] = useState('')
    const [prevToken, setPrevToken] = useState('')
    const [next, setNext] = useState(false)
    const [prev, setPrev] = useState(false)
    const [documents, setDocuments] = useState([])
    const [news, setNews] = useState([])
    const [publications, setPublications] = useState([])
    const [moduleVideos, setModuleVideos] = useState([])

    useEffect(() => {
        getResource(`documents?module.libUnique=textes-reglementaires`).then(res => {
            setDocuments(res.data['hydra:member'])
        })
        getResource(`publications?isPublicated=true&categorie.libUnique=nouveautes`).then(res => {
            setNews(res.data['hydra:member'])
        })
        getResource(`documents?module.libUnique=publications-dana`).then(res => {
            setPublications(res.data['hydra:member'])
        })
        youtube.get('/search', {
            params: {
                pageToken: nextToken
            }
        }).then(res => {
            setModuleVideos(res.data.items);

            setNextToken(res.data.nextPageToken)
        })
    }, [next])

    return (
        <div id="lesactualite">
            <h2 className="fw-bold text-center titleHolder">Publications</h2>
            <div className="container ps-0 pe-0">
                <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                    {/* <li className="nav-item" role="presentation">
                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#actualites"
                            type="button"
                            role="tab" aria-controls="home" aria-selected="true">Nouveautés
                        </button>
                    </li> */}
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#comminques" type="button"
                            role="tab"
                            aria-controls="profile" aria-selected="false">Publications
                        </button>
                    </li>
                    {/* <li className="nav-item" role="presentat">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#comminq" type="button"
                            role="tab"
                            aria-controls="profile" aria-selected="false">Textes Règlementaires
                        </button>
                    </li> */}
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#revuepresse" type="button"
                            role="tab"
                            aria-controls="video" aria-selected="false">Vidéothèque
                        </button>
                    </li>
                </ul>
                <div className="tab-content " id="myTabContent">
                    <div className="tab-pane fade " id="actualites" role="tabpanel">
                        <div className="row">
                            {
                                news.length > 0 ? news.slice(0, 3).map(act => (
                                    <div key={act.id} className="col-sm-12 col-lg-4 mt-3">
                                        <div className="single-actu-item">
                                            <div className="actu-image">
                                                <a href={`/actualites/${act.slug}`}>
                                                    {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} height='200px' alt="news 1"
                                                        className="img-fluid" />}
                                                    {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                                        className="img-fluid" />}
                                                </a>
                                                <div className="shareBtn">
                                                    <FacebookShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-facebook"></i>
                                                    </FacebookShareButton>
                                                    <WhatsappShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-whatsapp"></i>
                                                    </WhatsappShareButton>
                                                    <TelegramShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-telegram"></i>
                                                    </TelegramShareButton>
                                                    <TwitterShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`actualites/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-twitter"></i>
                                                    </TwitterShareButton>
                                                </div>
                                                <div className="actu-meta">
                                                    <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className="actu-text">
                                                <a href={`/actualites/${act.slug}`}>
                                                    <h4 className="">{act.titre.length > 60 ? act.titre.slice(0, 60) + '...' : act.titre}</h4>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="commiqueTitle">Aucune information disponible</h5>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade m-3" id="comminq" role="tabpanel">
                        {documents.length > 0 ? documents.slice(0, 4).map(document => (
                            <div>
                                <a href={document.fichier && process.env.REACT_APP_API_BASE_URL + '/' + document.fichier.contentUrl} className="list-group-item list-group-item-action" aria-current="true">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="commiqueTitle">{document.libelle}</h5>
                                        <p className="comminqueDate"><a href={document.fichier && process.env.REACT_APP_API_BASE_URL + '/' + document.fichier.contentUrl} target="_blank" className="btn btn-sm btn-outline-warning text-dark "
                                            title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a></p>
                                    </div>
                                </a>
                            </div>

                        )) :
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="commiqueTitle">Aucune information disponible</h5>
                            </div>
                        }
                    </div>
                    <div className="tab-pane fade show active m-3" id="comminques" role="tabpanel">
                        {publications.length > 0 ? publications.slice(0, 4).map(document => (
                            <div>
                                <a href={document.fichier && process.env.REACT_APP_API_BASE_URL + '/' + document.fichier.contentUrl} className="list-group-item list-group-item-action" aria-current="true">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="commiqueTitle">{document.libelle}</h5>
                                        <p className="comminqueDate"><a href={document.fichier && process.env.REACT_APP_API_BASE_URL + '/' + document.fichier.contentUrl} target="_blank" className="btn btn-sm btn-outline-warning text-dark "
                                            title="TELECHARGE"><i title="TELECHARGE" className="fas fa-download xs-font"></i></a></p>
                                    </div>
                                </a>
                            </div>

                        )) :
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="commiqueTitle">Aucune information disponible</h5>
                            </div>
                        }
                    </div>
                    <div className="tab-pane fade" id="revuepresse" role="tabpanel">
                        <ul className="gallery caption-1 mt-4">
                            {/* {
                                moduleVideos.slice(0, 3).map(module => (
                                    <li>
                                        <YoutubePlay vidId={`https://www.youtube.com/watch?v=${module.id.videoId}`} />
                                    </li>))
                            } */}
                            <YoutubePlay vidId={`https://www.youtube.com/watch?v=mXVzLYMHwQg`} />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Actualites;