import React, { useEffect, useState } from 'react'
import youtube from "../../services/youtube";
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    TelegramShareButton,
} from "react-share";
import moment from "moment"
import 'moment/locale/fr'
moment.locale('fr')



const ProjetProgramme = ({ home }) => {
    const [nextToken, setNextToken] = useState('')
    const [prevToken, setPrevToken] = useState('')
    const [next, setNext] = useState(false)
    const [prev, setPrev] = useState(false)
    const [moduleVideos, setModuleVideos] = useState([])
    useEffect(() => {
        // youtube.get('/search', {
        //     params: {
        //         pageToken: nextToken
        //     }
        // }).then(res => {
        //     setModuleVideos(res.data.items);

        //     setNextToken(res.data.nextPageToken)
        // })
    }, [next])

    useEffect(() => {

    }, [prev])

    return (
        <div id="lesactualite">
            <h2 className="fw-bold text-center titleHolder">Projets et Programmes</h2>
            <div className="container ps-0 pe-0">
                <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#agriculture"
                            type="button"
                            role="tab" aria-controls="home" aria-selected="true">AGRICULTURE
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#elevage" type="button"
                            role="tab"
                            aria-controls="profile" aria-selected="false">ELEVAGE
                        </button>
                    </li>

                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-uppercase" data-bs-toggle="tab" data-bs-target="#peche"
                            type="button"
                            role="tab"
                            aria-controls="contact" aria-selected="false">PECHE
                        </button>
                    </li>
                </ul>
                <div className="tab-content " id="myTabContent">
                    <div className="tab-pane fade show active" id="agriculture" role="tabpanel">
                        <div className="row">
                            {
                                home && home.agriculture.filter(a => a.categorie.libUnique == "agriculture").slice(0, 3).map(act => (
                                    <div key={act.id} className="col-sm-12 col-lg-4 mt-3">
                                        <div className="single-actu-item">
                                            <div className="actu-image">
                                                <a href={`/projets/${act.slug}`}>
                                                    {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt="news 1"
                                                        className="img-fluid" />}
                                                    {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                                        className="img-fluid" />}
                                                </a>
                                                <div>

                                                </div>
                                                <div className="shareBtn">
                                                    <FacebookShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`projets/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-facebook"></i>
                                                    </FacebookShareButton>
                                                    <WhatsappShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`projets/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-whatsapp"></i>
                                                    </WhatsappShareButton>
                                                    <TelegramShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`projets/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-telegram"></i>
                                                    </TelegramShareButton>
                                                    <TwitterShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_BASE_URL}/${`projets/${act.slug}`}`}>
                                                        <i
                                                            className="fab fa-twitter"></i>
                                                    </TwitterShareButton>
                                                </div>
                                                <div className="actu-meta">
                                                    <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className="actu-text ">
                                                <a href={`/projets/${act.slug}`}>
                                                    <h4 className="text-justify">{act.titre.length > 45 ? act.titre.slice(0, 45) + '...' : act.titre }</h4>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }

                        </div>
                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 waves-effect waves-orange waves-ripple">
                            <a href="/projets/categorie/Agriculture" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</a> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                    <div className="tab-pane fade" id="elevage" role="tabpanel">

                        {home && home.elevage.filter(a => a.categorie.libUnique == "elevage").slice(0, 3).map(act => (
                            <div key={act.id} className="col-sm-12 col-lg-4 mt-3">
                                <div className="single-actu-item">
                                    <div className="actu-image">
                                        <a href={`/projets/${act.slug}`}>
                                            {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt="news 1"
                                                className="img-fluid" />}
                                            {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                                className="img-fluid" />}
                                        </a>
                                        <div className="shareBtn">
                                            <FacebookShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${act.slug}`}>
                                                <i
                                                    className="fab fa-facebook"></i>
                                            </FacebookShareButton>
                                            <WhatsappShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${act.slug}`}>
                                                <i
                                                    className="fab fa-whatsapp"></i>
                                            </WhatsappShareButton>
                                            <TelegramShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${act.slug}`}>
                                                <i
                                                    className="fab fa-telegram"></i>
                                            </TelegramShareButton>
                                            <TwitterShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${act.slug}`}>
                                                <i
                                                    className="fab fa-twitter"></i>
                                            </TwitterShareButton>

                                        </div>
                                        <div className="actu-meta">
                                            <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                        </div>
                                    </div>
                                    <div className="actu-text">
                                        <a href={`/projets/${act.slug}`}>
                                            <h3 className="text-uppercase">{act.titre.length > 45 ? act.titre.slice(0, 45) + '...' : act.titre }</h3>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        ))}

                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 waves-effect waves-orange waves-ripple">
                            <a href="/projets/categorie/Elevage" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</a> <i
                                className="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                    <div className="tab-pane fade " id="peche" role="tabpanel">
                        <div className="row">
                            {
                                home && home.peche.filter(a => a.categorie.libUnique == "peche").slice(0, 3).map(act => (
                                    <div key={act.id} className="col-sm-12 col-lg-4 mt-3">
                                        <div className="single-actu-item">
                                            <div className="actu-image">
                                                <a href={`/projets/${act.slug}`}>
                                                    {act.media && <img src={process.env.REACT_APP_API_BASE_URL + '/' + act.media.contentUrl} alt="news 1"
                                                        className="img-fluid" />}
                                                    {!act.media && <img src={'/img/noimage.jpg'} alt="news 1"
                                                        className="img-fluid" />}
                                                </a>
                                                <div className="shareBtn">
                                                    <FacebookShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${act.slug}`}>
                                                        <i
                                                            className="fab fa-facebook"></i>
                                                    </FacebookShareButton>
                                                    <WhatsappShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${act.slug}`}>
                                                        <i
                                                            className="fab fa-whatsapp"></i>
                                                    </WhatsappShareButton>
                                                    <TelegramShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${act.slug}`}>
                                                        <i
                                                            className="fab fa-telegram"></i>
                                                    </TelegramShareButton>
                                                    <TwitterShareButton className="secdText m-2" title={act && act.titre} url={`${process.env.REACT_APP_API_BASE_URL}/${act.slug}`}>
                                                        <i
                                                            className="fab fa-twitter"></i>
                                                    </TwitterShareButton>

                                                </div>
                                                <div className="actu-meta">
                                                    <p>Publié le {moment(act.createdAt).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <div className="actu-text">
                                                <a href={`/projets/${act.slug}`}>
                                                    <h3 className="text-uppercase">{act.titre.length > 45 ? act.titre.slice(0, 45) + '...' : act.titre }</h3>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }

                        </div>

                        <button className="actu-btn  mx-auto d-block mt-4 mb-3 waves-effect waves-orange waves-ripple">
                            <a href="/projets/categorie/Pêche" style={{ marginTop: -3, color: '#FFF' }}>VOIR PLUS</a> <i
                                className="fas fa-long-arrow-alt-right"></i></button>

                    </div>

                </div>
            </div>
        </div>

    );
}

export default ProjetProgramme;