import React, { useEffect } from 'react';
import Slider from "react-slick";
import { Card } from 'reactstrap';
import { sliderConfig } from '../../constants';

const SliderAccueil = ({ home }) => {

    return (
        <div id="Homeslider" className="container-fluid pt-0">
            <div className="row">

                <div className="slick-slider">
                    <div className="slick-slider-init">
                        <Slider {...sliderConfig}>
                            {
                                home && home.actus_slider.map(slider => (
                                    <div className="slick-item" key={slider.id}>
                                        <div class="dgi-slide-item w-100 bg-secondary text-white"
                                            style={slider.media && { backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + '/' + slider.media.contentUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                            <div class="dgi-slide-caption">
                                                <div class="captionContent">
                                                    {slider.titre.length > 150 ?
                                                        <h4 data-animation-in="slideInRight">
                                                            <a className="text-white" href={`/actualites/${slider.slug}`}> {slider.titre }</a>
                                                        </h4> :
                                                        <h3 data-animation-in="slideInRight">
                                                            <a className="text-white" href={`/actualites/${slider.slug}`}> {slider.titre }</a>
                                                        </h3>
                                                    }
                                                    
                                                    <a style={{ backgroundColor: "#fff" }} href={`/actualites/${slider.slug}`} className="btn btn-succes btn-lg mt-5 waves-effect waves-light"
                                                        data-animation-in="slideInLeft">Lire la suite
                                                        <i className="fas fa-long-arrow-alt-right" style={{ paddingLeft: '10px' }}></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderAccueil;