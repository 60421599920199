import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';
import Moment from 'moment';
import Loader from '../../lib/loader';
import moment from 'moment';
import { ITEM_PER_PAGE } from '../../constants';

function AppelOffre() {
    const [page, setPage] = useState('');
    const [search, setSearch] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)
    var currentDate = new Date();
    console.log(currentDate.getTime())


    useEffect(() => {
        loadData()
    }, [search]);

    useEffect(() => {
        loadData()
    }, [currentPage]);

    const loadData = () => {
        setLoading(true)
        getResource(`offres?isPublicated=true&itemsPerPage=8&page=${currentPage}${search ? `&titre=${search}` : ''}`).then(res => {
            setPage(res.data['hydra:member'])
            setAppState({ ...appState, parent_menu: parent })
            setLoading(false)
            let array_pages = Math.ceil(parseInt(res.data['hydra:totalItems']) / parseInt(ITEM_PER_PAGE))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Appels d'offres</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="">ACCUEIL</a> - </span>
                            <span>Appels d'offres</span>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container mb-3 mt-3">

                <div className="searchContainer dgishadow rounded mb-3">

                    <div className={`search actulite ${openSearch && 'open'}`} onClick={() => { setOpenSearch(true) }}>

                        <div className="icon" title="Clique pour Recherchez">
                            <span>
                                <svg viewBox="0 0 40 40">
                                    <path d="M3,3 L37,37"></path>
                                </svg>
                            </span>
                        </div>
                        <div className="field">
                            <input type="text" name="search" value={search} placeholder="Que recherchez-vous ?" onChange={(event) => setSearch(event.target.value)} />
                        </div>
                    </div>
                </div>

                {loading == true ? <Loader /> : page.length == 0 ? <div className="d-flex w-100 justify-content-between">
                    <h5 className="commiqueTitle text-center">Aucune donnée disponible</h5>
                </div> : page && <div className="row" style={{ position: 'relative' }}>
                
                <div className="row">
                    {page.map((offre) => (
                        <div className="col-md-6 p-4 card offre-main mb-5 dgishadow" style={{ minHeight: 300 }}>
                            <p className="comminqueDate text-muted "><i className="fas fa-calendar-day"></i>
                                <span style={{ paddingRight: '20px', paddingLeft: '10px' }}>
                                Du {Moment(offre.debut).format("DD-MM-YYYY")} au {Moment(offre.fin).format("DD-MM-YYYY")}
                                </span>
                            </p>
                            <p className="text-center mt-2">
                                {currentDate.getTime() > new Date(offre.fin).getTime() ? <div className="expire-content"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Cet appel d'offre a déjà expiré</div> : <div></div>}
                                <div className="offre-content">{offre.titre}</div>
                                {offre && typeof offre.media !== 'undefined'
                                    &&
                                    <a className="actu-btn  mx-auto d-block mt-4 mb-3 waves-effect waves-orange waves-ripple" href={process.env.REACT_APP_API_BASE_URL + '/' + offre.media.contentUrl} style={{ marginTop: -3, color: '#FFF' }}>Télécharger le document</a>
                                } 
                            </p>
                        </div>
                    ))}
                </div>
                

                    {/* {page.map((offre, index) => (
                        page.length - 1 == index ?
                            <div className="row m-1">
                                <div className="col-md-3"></div>
                                <div className="col-md-6 p-4 card offre-main mb-5 dgishadow" style={{ minHeight: 300 }}>
                                    <p className="comminqueDate"><i className="fas fa-calendar-day"></i>
                                        <span style={{ paddingRight: '20px', paddingLeft: '10px' }}>
                                            Période: {Moment(offre.debut).format("DD-MM-YYYY")} - {Moment(offre.fin).format("DD-MM-YYYY")}
                                        </span>
                                    </p>
                                    <p className="text-center">
                                        {currentDate > offre.fin ? <div className="expire-content">Cet appel d'offre a déjà expiré</div> : <div></div>}
                                        <div className="offre-content">{offre.titre}</div>
                                        {offre && typeof offre.media !== 'undefined'
                                            &&
                                            <a className="actu-btn  mx-auto d-block mt-4 mb-3 waves-effect waves-orange waves-ripple" href={process.env.REACT_APP_API_BASE_URL + '/' + offre.media.contentUrl} style={{ marginTop: -3, color: '#FFF' }}>Télécharger le document</a>
                                        }
                                    </p>
                                </div>
                                <div className="col-md-3"></div>
                            </div> :
                            <div className="col-md-6 p-4 card offre-main mb-5 dgishadow" style={{ minHeight: 300 }}>
                                <p className="comminqueDate"><i className="fas fa-calendar-day"></i>
                                    <span style={{ paddingRight: '20px', paddingLeft: '10px' }}>
                                    Période: {Moment(offre.debut).format("DD-MM-YYYY")} - {Moment(offre.fin).format("DD-MM-YYYY")}
                                    </span>
                                </p>
                                <p className="text-center">
                                    {currentDate > offre.fin ? <div className="expire-content">Cet appel d'offre a déjà expiré</div> : <div></div>}
                                    <div className="offre-content">{offre.titre}</div>
                                    {offre && typeof offre.media !== 'undefined'
                                        &&
                                        <a className="actu-btn  mx-auto d-block mt-4 mb-3 waves-effect waves-orange waves-ripple" href={process.env.REACT_APP_API_BASE_URL + '/' + offre.media.contentUrl} style={{ marginTop: -3, color: '#FFF' }}>Télécharger le document</a>
                                    } 
                                </p>
                            </div>
                    ))} */}
                </div>}
                <div className="searchContainer dgishadow rounded">

                    <div>
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <h3 style={{ color: "#fff" }}>Appels d'offres</h3>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="x-scroll">
                        <nav className="courses-pagination pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppelOffre;