import React, { useEffect, useState } from 'react';
import { getResource } from '../../services/api';
import Actualites from './actualites';
import Contact from './contact';
import EcheanceFiscale from './echeance_fiscale';
import Regime from './regime';
import ServiceUsager from './service_usager';
import ServiceUsuel from './service_usuel';
import SliderAccueil from './slider_accueil';
import CountNumber from './count_number';
import ProjetProgramme from './projet_programme';
import PoleDeveloppement from './pole_developpement';

const Dana = () => {
    const [home, setHome] = useState(null);
    useEffect(() => {
        getResource(`home`).then(res => {
            setHome(res.data)
        })
    }, [])
    return (
        <>
            <SliderAccueil home={home} />
            {/* <Contact home={home} /> */}
            {/* <CountNumber home={home} /> */}
            {/* <ProjetProgramme home={home} /> */}
            {/* <PoleDeveloppement home={home} /> */}
            {/* <Regime home={home}  /> */}
            <ServiceUsuel home={home} />
            {/* <ServiceUsager home={home} /> */}
            <EcheanceFiscale home={home} />
            <Actualites home={home} />
            <CountNumber home={home} />
        </>
    );
}

export default Dana;