import React from 'react';
import moment from "moment"
import 'moment/locale/fr'
moment.locale('fr')

const EcheanceFiscale = ({ home }) => {
    return (
        <div id="echeance">
            <h2 className="fw-bold text-center titleHolder">Evènements
            </h2>
            <div className="container pt-2 mt-5 mb-4">
                <div className="content">
                    <div className="gw">
                        <div className="row mt-3">
                            <div className="col-sm-12 col-md-6 g one-whole tablet-two-thirds">
                                <div className="event-cards-container">
                                    {
                                        home && home.echeance_fiscale.slice(0, 4).map((ef, index) => {
                                            let color = index + 1
                                            if (index > 4) color = parseInt(index % 5) + 1
                                            return (
                                                <div key={ef.id} className={`event event-card one-half alternate-${color}`}>
                                                    <div class="event-card-date">
                                                        <div class="event-start">
                                                            <div class="event-start-date">{moment(ef.dateDebut).format('DD')}</div>
                                                            <div class="event-start-month"><small>{moment(ef.dateDebut).format('MMMM')}</small></div>
                                                        </div>
                                                        <div class="event-end">
                                                            <div class="event-end-date">-&nbsp;{moment(ef.dateButoire).format('DD')}</div>
                                                            <div class="event-end-month"><small>&nbsp;{moment(ef.dateButoire).format('MMMM')}</small></div>
                                                        </div>
                                                    </div>
                                                    <div class="event-card-details">
                                                        <div class="event-title">{ef.libelle}</div>
                                                        <div class="event-time">
                                                            <small>
                                                                {ef.description.slice(0,500)}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EcheanceFiscale;