import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getResource } from '../../services/api';
import { AppContext } from '../layout';

function Cabinet() {
    const [page, setPage] = useState('');
    const [directions, setDirections] = useState('');
    const [isActive, setIsActive] = useState(true);
    let { slug, parent } = useParams();
    const { appState, setAppState } = useContext(AppContext)


    useEffect(() => {
        getResource(`cabinets`).then(res => {
            setDirections(res.data['hydra:member'])
            setPage(res.data['hydra:member'][0])
            setAppState({ ...appState, parent_menu: parent })
        })
    }, []);

    const getDirection = (libelle) => {
        getResource(`cabinets?titre=${libelle}`).then(res => {
            setPage(res.data['hydra:member'][0])
        })
    }

    return (
        <div id="main">
            <div className="col-md-12">
                <div className="page-info">
                    <h2 className="text-center text-white">Directions de Cabinets</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="">ACCUEIL</a> -</span>
                            <span>Ministère -</span>
                            <span>Directions de Cabinets</span>
                        </div>
                    </div>
                </div>


            </div>
            <div className="container mb-3 mt-3">

                {page && <div className="row" style={{ position: 'relative' }}>

                    {directions && <div className="col-md-3">
                        <ul className="list-group dgi-list-group">
                            {
                                directions.map(dir => (
                                    <div className="card dgishadow" style={{ position: 'relative', zIndex: 3 }} >
                                        {dir.media && <img src={`${process.env.REACT_APP_API_BASE_URL}/${dir.media.contentUrl}`} alt={dir.titre} className="img-fluid" style={{ height: '250px' }} />}
                                        <button className='btn m-2 projet_content_key1  text-center' onClick={() => getDirection(dir.titre)}>
                                            <li className={`list-group-item ac ${page.id == dir.id ? `active` : ``}`}>{dir.titre}</li>
                                        </button>
                                    </div>
                                    // <li className={`list-group-item ${page.id == dir.id ? `` : `drop-down-menu`}`}><a onClick={() => getDirection(dir.titre)} href="#">{dir.titre}</a></li>
                                ))
                            }
                        </ul>
                    </div>}

                    <div className="col-sm-12 col-md-9 p-4 card dgishadow" style={{ minHeight: 400 }}>
                        <div className="projet_titre">
                            <h4>Direction de cabinet du Ministère de l'Agriculture, de l'Elevage et de la Pêche</h4>
                        </div>
                        <div className="w-25 divider">
                            &nbsp;
                        </div>

                        <div className="row">
                            <div className="d-flex dgishadow mb-3">
                                <div className="p-1 pe-2 bd-highlight textG titre-head1">
                                    <span className="">Nom </span>
                                </div>

                                <div className="projet_content_key1 p-2">{"  "} {page.nom + " " + page.prenom}</div>
                            </div>
                            <div className="d-flex dgishadow mb-3">
                                <div className="p-1 pe-2 bd-highlight textG titre-head1">
                                    <span className="">Titre </span>
                                </div>

                                <div className="projet_content_key1 p-2">{"  "} {page.titre}</div>
                            </div>

                        </div>&nbsp;
                        <div>
                            <h6 className="textG mb-2">Description</h6>
                            <div className="projet_key1" dangerouslySetInnerHTML={{ __html: `${page.contenu}` }}></div>
                        </div> &nbsp;
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Cabinet;